<template>
  <div class="index_wrap">
    <h1 class="h_none">商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!--  -->
    <div style="height: 74px"></div>
    <section class="top">新闻/动态</section>
    <section class="document">
      <div :class="['document_title',{'active': isFlag}]" >
        <p :style="{'color': !isFlag ? '#FFFFFF' : '#333333'}"  @click="switchTab(0)">企业动态</p>
        <p :style="{'color': !isFlag ? '#333333' : '#FFFFFF'}"  @click="switchTab(1)">行业新闻</p>
      </div>

      <div v-if="!isFlag"  class="document_cen">
        <ul  v-for="(item,index) in listArr" :key="index" @click="toDetailEvent(item.id)" class="document_item">
          <li>
            <img style="height: 140px;width: 250px;border-radius: 8px;" :src="item.thumbnail" alt="">
          </li>
          <li style="height: 140px;display: flex;flex-direction: column">
            <p class="title">{{item.title}}</p>
            <div class="description">{{item.description}}</div>
            <p class="detail">
              <span>{{item.updateTime}}</span>
            </p>
          </li>
        </ul>

<!--        <div class="block">-->
<!--          <el-pagination-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page.sync="currentPage3"-->
<!--            :page-size="100"-->
<!--            background-->
<!--            layout="prev, pager, next, jumper"-->
<!--            :total="1000">-->
<!--          </el-pagination>-->
<!--        </div>-->
      </div>

      <div v-else  class="document_cen">
        <ul  v-for="(item,index) in sublistArr" :key="index" @click="toDetailEvent(item.id)" class="document_item">
          <li>
            <img style="height: 140px;width: 250px;border-radius: 8px;" :src="item.thumbnail" alt="">
          </li>
          <li style="height: 140px;display: flex;flex-direction: column">
            <p class="title">{{item.title}}</p>
            <div class="description">{{item.description}}</div>
            <p class="detail">
              <span>{{item.updateTime}}</span>
            </p>
          </li>
        </ul>
      </div>

    </section>
<!--    <div class="content">-->
<!--      <div class="content_cen">-->
<!--        <ul style="width: 100%">-->
<!--          <li v-for="(item,index) in listArr" :key="index" @click="toDetailEvent(item.id)" :style="{flexDirection:index % 2 == 0 ? 'row' : 'row-reverse'}" style="width: 100%;display: flex;cursor: pointer">-->
<!--            <div :style="index % 2 == 0 ? 'marginRight:30px' : 'marginLeft:30px'">-->
<!--              <img style="height: 225px;width: 360px" :src="'https://testimage.kangxin.com/'+item.thumbnail" alt="">-->
<!--            </div>-->

<!--            <div :style="index % 2 == 0 ? 'text-align:left'  : 'text-align:right' " style="display: flex;flex-direction: column;justify-content: space-between">-->
<!--              <div class="title">-->
<!--                <p>{{item.title}}</p>-->
<!--                <p style="margin-top: 23px">{{item.updateTime}}</p>-->
<!--                <p style="margin-top: 15px">{{item.author}}</p>-->
<!--              </div>-->

<!--              <div :style="index % 2 == 0 ?'text-align:left' :  'text-align:right'" class="source">-->
<!--                {{item.tag}}-->
<!--              </div>-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
    <footers></footers>
  </div>
</template>

<script>
  import { JOURNAL, JOURNALDETAIL } from "@/request/common";
  import footers from '@/components/common/footers'
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '蓝灯鱼,蓝灯鱼商标、蓝灯鱼翻译、蓝灯鱼专利、蓝灯鱼AI,蓝灯鱼IP,人工智能,知识产权,商标查询,专利翻译,机器翻译,机器学习,专利检索,商标查询api,专利翻译api,管理系统,品牌保护,商标监控,智慧园区'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是提供商标查询，专利检索，专利翻译服务的知识产权智能检索平台，应用人工智能技术，已发布商标图形查询，专利翻译，CPC官文提取工具，知识产权管理系统等系列产品。'
      }],
    },
    data () {
      return {
        activeName: 'second',
        listArr: [],
        sublistArr: [],
        isFlag: false,
        currentPage3: 5
      }
    },
    mounted() {
      this.init();
    },
    components: {
      footers
    },
    methods: {
      switchTab(value){
        this.isFlag = value ? true : false
      },
      init () {
        JOURNAL({
          status: 1,
          language: "zh"
        })
          .then(data => {
            if ((data.msg = "success")) {
              data.data.map(item => {
                if(item.type == 1) { this.listArr.push(item) }
                else {
                  this.sublistArr.push(item)
                }
                return item;
              });
            } else {
              this.$message(data.msgEn);
            }
          })
          .catch(error => {
            // this.$message("系统未知错误,请反馈给管理员");
            console.log(error);
          });
      },
      toDetailEvent (paramId) {
        let routeUrl = this.$router.resolve({
          path: "/journalDetail",
          query: {
            id: paramId
          }
        });
        window.open(routeUrl.href, "_blank");
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    .top{
      text-align: center;
      font-size: 32px;
      font-weight: 500;
      color: #333333;
      position: relative;
      width: 158px;
      margin: 46px auto 0;
      margin-bottom: 51px;
      &:before{
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 158px;
        height: 12px;
        background: rgba(38,77,216,0.58);
        border-radius: 5px;
      }
    }
    .document{
      width: 1199px;
      margin: 0 auto;
      height: auto;

      @keyframes scaleDraw {  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
        0%{
          transform: scale(1);  /*开始为原始大小*/
        }
        25%{
          transform: scale(1.025); /*放大1.1倍*/
        }
        50%{
          transform: scale(1.05);
        }
        75%{
          transform: scale(1.1);
        }
      }
      .document_title{
        position: relative;
        width: 356px;
        height: 58px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: url('../../assets/images/news/news1.png') no-repeat 100% 100%;
        &.active{
          background: url('../../assets/images/news/news2.png') no-repeat 100% 100%;
        }
        p{
          position: absolute;
          cursor: pointer;
          font-size: 21px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 29px;
          &:nth-of-type(1){
            left: 44px;
            top: 16px;
          }
          &:nth-of-type(2){
            left: 211px;
            top: 16px;
          }
        }
      }
      .document_cen{
        width: 1200px;
        min-height: 500px;
        background: #E2E8F6;
        padding: 31px 15px 42px 14px;
        box-sizing: border-box;
        border-radius: 0px 10px 10px 10px;
        .document_item{
          display: flex;
          align-items: center;
          cursor: pointer;
          /*justify-content: space-between;*/
          padding: 22px 21px 0px 25px;
          box-sizing: border-box;
          width: 1170px;
          height: 188px;
          background: #FFFFFF;
          box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.12);
          border-radius: 10px;
          position: relative;
          margin-bottom: 24px;
          .title{
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 1;
          }
          .description{
            height: 72px;
            margin-top: 15px;
            font-size: 14px;
            font-weight: 400;
            color: #888888;
            line-height: 24px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
          .detail{
            font-size: 14px;
            font-weight: 300;
            color: #666666;
            margin-top: 15px;
          }
          & li:nth-of-type(1){
            margin-right: 23px;
          }
          & li:nth-of-type(3){
            position: absolute;
            right: 41px;
            top: 53px;
          }
          &:hover{
            transform:scale(1.027,1);
            height: 196px;
            box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.26);
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .block{
          margin-top: 28px;
          text-align: right;
        }
      }
    }
  }
</style>
